import { signEmailFooter, signEmailHeader, logLocalTime } from "./addVerification";

/* global Office */
let mailboxItem;

Office.initialize = function () {
  mailboxItem = Office.context.mailbox.item;
};

Office.actions.associate("signEmailFooter", signEmailFooter);
Office.actions.associate("signEmailHeader", signEmailHeader);
Office.actions.associate("actionNotification", actionNotification);
Office.actions.associate("logLocalTime", logLocalTime);
Office.actions.associate("onMessageSendHandler", onMessageSendHandler);

async function onMessageSendHandler(event) {
  try {
    //    const meetingData = new Date().toISOString();
    await signEmailFooter(event);
    event.completed({ allowEvent: true });
  } catch (error) {
    console.error(error);
    event.completed({
      allowEvent: false,
      errorMessage: `An error occurred: ${error.message}`,
      cancelLabel: "Close",
      commandId: "msgReadOpenPaneButton",
      sendModeOverride: Office.MailboxEnums.SendModeOverride.PromptUser,
    });
  }
}

function parseJwt(token: string): any {
  try {
    // Split the token into parts
    const base64Url = token.split(".")[1];
    // Replace '-' with '+' and '_' with '/' to make the base64 string URL safe
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    // Decode the base64 string
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    // Parse the JSON string
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
}

async function actionNotification(event: Office.AddinCommands.Event) {
  // Check if user is logged in by verifying the token
  const tokenString = localStorage.getItem("tg-user");
  if (tokenString) {
    try {
      const token = JSON.parse(tokenString);
      const verifyToken = token.state?.vtoken;

      if (verifyToken) {
        const decodedToken = parseJwt(verifyToken);

        console.log("Decoded token:", decodedToken);
        if (decodedToken.OutlookType === "AppSource") {
          displayAppSourceNotification();
        } else {
          displayAdminSourceNotification();
          console.log("User is not an Admin.");
        }

        // Log recipient email addresses
        const recipients = await getRecipients();
        console.log("Recipients:", recipients);
      } else {
        console.log("Verification token not found in the state.");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  } else {
    console.log("User is not logged in.");
  }

  event.completed();
}

const displayAppSourceNotification = () => {
  const id = "notification1";
  const details = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InsightMessage,
    message: "Remember to sign this message with Trustguid",
    icon: "icon1",
    actions: [
      {
        actionText: "Add",
        actionType: Office.MailboxEnums.ActionType.ShowTaskPane,
        commandId: "msgComposeOpenPaneButton",
        contextData: null,
      },
    ],
  };

  Office.context.mailbox.item?.notificationMessages.addAsync(id, details, handleResult);
};

const displayAdminSourceNotification = () => {
  const id = "notification1";
  const details = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InsightMessage,
    message: "This message will be signed by TrustGuid",
    icon: "icon1",
    actions: [
      {
        actionText: "Add",
        actionType: Office.MailboxEnums.ActionType.ShowTaskPane,
        commandId: "msgComposeOpenPaneButton",
        contextData: null,
      },
    ],
  };

  Office.context.mailbox.item?.notificationMessages.addAsync(id, details, handleResult);
};

const handleResult = (asyncResult: Office.AsyncResult<any>) => {
  if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
    console.log("Notification added successfully.");
  } else {
    console.error("Failed to add notification: " + asyncResult.error.message);
  }
};

// Function to fetch recipients
async function getRecipients() {
  const toRecipients = await getRecipientsByType("to");
  const ccRecipients = await getRecipientsByType("cc");
  const bccRecipients = await getRecipientsByType("bcc");

  return {
    to: toRecipients,
    cc: ccRecipients,
    bcc: bccRecipients,
  };
}

function getRecipientsByType(type: "to" | "cc" | "bcc"): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const item = Office.context.mailbox.item;
    if (item && item[type]) {
      item[type].getAsync((result: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value.map((recipient) => recipient.emailAddress));
        } else {
          reject(result.error);
        }
      });
    } else {
      resolve([]);
    }
  });
}
